import React from 'react';
import { Navigate } from 'react-router-dom';
import AuthService from '@/merchant/services/auth';

export default function LoginPage() {
  let route = `/merchants/${AuthService.getMerchantUuid()}/transactions`; // TODO: Replace with Dashboard
  const isTechnician = AuthService.hasRole(['merchant_technician']);

  if (isTechnician) {
    route = '/merchants/stations';
  }

  if (AuthService.isLoggedIn()) {
    return <Navigate replace to={route} />;
  }
  return <>{AuthService.doLogin()}</>;
}
