import React, { useState } from 'react';
import {
  Box,
  Button,
  FormControl,
  IconButton,
  InputAdornment,
  InputLabel,
  MenuItem,
  Modal,
  OutlinedInput,
  Select,
  TextField,
  Typography,
} from '@mui/material';
import { Formik } from 'formik';
import { useParams } from 'react-router-dom';
import * as Yup from 'yup';
import InputMask from 'react-input-mask';
import {
  boxStyle,
  cancelStyle,
  submitStyle,
} from '@/merchant/components/common/styles';
import { phoneRegex } from '@/merchant/components/helpers/regex';
import {
  addUserService,
  setPasswordService,
} from '@/merchant/services/merchant';
import { Mixpanel } from '@/merchant/config/mixpanel';
import { VisibilityOff, Visibility } from '@mui/icons-material';
import useMerchantLocations from '@/merchant/hooks/useMerchantLocations';

interface AddMemberModalProps {
  handleClose: () => void;
  setShowSuccessAlert: React.Dispatch<React.SetStateAction<boolean>>;
  setShowErrorAlert: React.Dispatch<React.SetStateAction<boolean>>;
  setAlertMessage: React.Dispatch<React.SetStateAction<string>>;
  setLoading: React.Dispatch<React.SetStateAction<boolean>>;
}

const FORM_INPUT_FIELDS = [
  {
    value: 'firstName',
    label: 'First Name',
    type: 'text',
  },
  {
    value: 'lastName',
    label: 'Last Name',
    type: 'text',
  },
  {
    value: 'role',
    label: 'role',
    type: 'text',
  },
  {
    value: 'phoneNumber',
    label: 'Phone Number',
    type: 'text',
  },
  {
    value: 'email',
    label: 'Email',
    type: 'email',
  },
  {
    value: 'password',
    label: 'Password',
    type: 'password',
  },
];

const validationSchemaObject = Yup.object().shape({
  role: Yup.string().required('Required field'),
  firstName: Yup.string().required('Required field'),
  lastName: Yup.string().required('Required field'),
  email: Yup.string().email().required('Required field'),
  phoneNumber: Yup.string()
    .matches(phoneRegex, 'Invalid phone number')
    .required('Required field'),
  password: Yup.string().required('Required field'),
  locationUuid: Yup.string().when('role', {
    is: 'STORE_ADMIN',
    then: Yup.string().required('Select a location'),
  }),
});

const initialValues = {
  role: '',
  firstName: '',
  lastName: '',
  phoneNumber: '',
  email: '',
  password: '',
  locationUuid: '',
};

const renderContent = (
  values: any,
  errors: any,
  handleChange: any,
  handleBlur: any,
  touched: any,
  showPassword: boolean,
  setShowPassword: React.Dispatch<React.SetStateAction<boolean>>,
) =>
  FORM_INPUT_FIELDS.map((field) => {
    const { value, label, type } = field;

    switch (value) {
      case 'phoneNumber':
        return (
          <InputMask
            key={value}
            mask="(999) 999-9999"
            value={values.phoneNumber}
            onChange={handleChange}
            onBlur={handleBlur}
          >
            <TextField
              key={`${value}-${label}`}
              id={value}
              label={label}
              variant="outlined"
              fullWidth
              onChange={handleChange}
              sx={{ marginBottom: 2 }}
              error={touched[value] && !!errors[value]}
              helperText={touched[value] && errors[value]}
              type={type}
            />
          </InputMask>
        );
      case 'role':
        return (
          <FormControl fullWidth>
            <InputLabel>Role</InputLabel>
            <Select
              value={values.role}
              label="Role"
              name="role"
              onChange={handleChange}
              sx={{ marginBottom: 2 }}
              type={type}
            >
              <MenuItem value="MERCHANT_ADMIN">Merchant Admin</MenuItem>
              <MenuItem value="STORE_ADMIN">Store Admin</MenuItem>
            </Select>
          </FormControl>
        );
      case 'password':
        return (
          <FormControl fullWidth>
            <InputLabel htmlFor="outlined-adornment-password">
              {label}
            </InputLabel>
            <OutlinedInput
              key={`${value}-${label}`}
              id={value}
              label={label}
              onChange={handleChange}
              sx={{ marginBottom: 2 }}
              error={touched[value] && !!errors[value]}
              type={showPassword ? 'text' : 'password'}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={() => setShowPassword(!showPassword)}
                  >
                    {showPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              }
            />
          </FormControl>
        );
      default:
        return (
          <TextField
            key={`${value}-${label}`}
            id={value}
            label={label}
            variant="outlined"
            fullWidth
            onChange={handleChange}
            sx={{ marginBottom: 2 }}
            error={touched[value] && !!errors[value]}
            helperText={touched[value] && errors[value]}
            type={type}
          />
        );
    }
  });

const AddMemberModal = ({
  handleClose,
  setShowSuccessAlert,
  setShowErrorAlert,
  setAlertMessage,
  setLoading,
}: AddMemberModalProps) => {
  const { merchantUuid } = useParams();
  const [showPassword, setShowPassword] = useState(false);
  const { merchantLocations } = useMerchantLocations(merchantUuid);

  const addMember = async (values: any) => {
    if (!merchantUuid) {
      return;
    }

    const payloadValues = {
      user: {
        ...values,
      },
      assigned_merchants: [merchantUuid],
      assigned_locations: [values.locationUuid],
    };

    addUserService(payloadValues)
      .then((response) => {
        handleClose();
        if (response.status === 200) {
          const passwordValues = {
            password: values.password,
            temporary: true,
            entityUuid: response.data.user.uuid,
            identityUuid: response.data.user.identityUuid,
          };
          setPasswordService(merchantUuid, passwordValues)
            .then(() => {
              setShowSuccessAlert(true);
            })
            .catch(() => {
              setShowErrorAlert(true);
            })
            .finally(() => {
              setLoading(false);
            });
          Mixpanel.track('member_added', {
            firstName: values.firstName,
            lastName: values.lastName,
            phoneNumber: values.phoneNumber,
            email: values.email,
          });
        } else {
          setAlertMessage(response.statusText);
          setShowErrorAlert(true);
        }
      })
      .catch(() => {
        setShowErrorAlert(true);
      });
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchemaObject}
      onSubmit={async (values) => {
        await addMember(values);
        setLoading(true);
      }}
    >
      {({
        values,
        errors,
        handleChange,
        handleBlur,
        handleSubmit,
        touched,
      }: any) => (
        <Modal open onClose={handleClose} aria-labelledby="modal-title">
          <form onSubmit={handleSubmit}>
            <Box sx={boxStyle}>
              <Typography
                id="modal-title"
                variant="h6"
                component="h1"
                sx={{ mb: 2, mt: 1 }}
              >
                Add Member
              </Typography>
              {renderContent(
                values,
                errors,
                handleChange,
                handleBlur,
                touched,
                showPassword,
                setShowPassword,
              )}
              {values.role === 'STORE_ADMIN' && (
                <>
                  <FormControl fullWidth sx={{ mt: 2 }}>
                    <TextField
                      id="outlined-select-currency"
                      select
                      label="Location"
                      name="locationUuid"
                      value={values.locationUuid}
                      error={touched.locationUuid && !!errors.locationUuid}
                      onChange={handleChange}
                    >
                      {merchantLocations.map((option) => (
                        <MenuItem key={option.uuid} value={option.uuid}>
                          {option.name}
                        </MenuItem>
                      ))}
                    </TextField>
                  </FormControl>
                  {values.locationUuid ? (
                    <FormControl sx={{ mt: 2 }}></FormControl>
                  ) : null}
                </>
              )}
              <Box display="flex" mt={3} justifyContent="flex-end">
                <Button sx={cancelStyle} onClick={handleClose}>
                  Cancel
                </Button>
                <Button
                  size="small"
                  color="secondary"
                  sx={submitStyle}
                  type="submit"
                  variant="contained"
                >
                  Submit
                </Button>
              </Box>
            </Box>
          </form>
        </Modal>
      )}
    </Formik>
  );
};

export default AddMemberModal;
