import { useEffect, useState } from 'react';
import { listMerchantLocationsService } from '../services/merchant';

interface MerchantLocation {
  uuid: string;
  name: string;
  lat: string;
  long: string;
  tid: string;
}

const useMerchantLocations = (merchantUuid: string | undefined) => {
  const [reload, setReload] = useState(false);
  const [merchantLocations, setMerchantLocations] = useState<
    MerchantLocation[]
  >([
    {
      uuid: '',
      name: '',
      lat: '',
      long: '',
      tid: '',
    },
  ]);

  const shouldReload = reload === true;
  useEffect(() => {
    const getData = async () => {
      await listMerchantLocationsService(merchantUuid || '').then(
        (response: any) => {
          if (response.isAxiosError) {
            throw Error(response.response.data.message);
          } else {
            response.data.locations.sort((a: any, b: any) =>
              a.name.localeCompare(b.name),
            );
            setMerchantLocations(response.data.locations);
          }
        },
      );
    };
    getData();
    if (shouldReload) setReload(false);
  }, [shouldReload]);

  return { merchantLocations, setReload, reload };
};

export default useMerchantLocations;
