import React, { useEffect, useMemo } from 'react';
import { Toaster } from 'react-hot-toast';
import { BrowserRouter } from 'react-router-dom';

import AuthService from '@/merchant/services/auth';
import { Mixpanel, initMixpanel } from '@/merchant/config/mixpanel';
import OnrampRouter from './components/navigation/OnrampRouter';
import './config/axios.ts';
import { ApolloClient, ApolloProvider, InMemoryCache } from '@apollo/client';
import { Config } from '@/merchant/config/env';

const App = () => {
  useEffect(() => {
    initMixpanel();
    Mixpanel.identify(AuthService.getUserEmail());
  }, []);

  const token = AuthService.getToken();

  const client = useMemo(() => {
    return new ApolloClient({
      uri: Config.GQL_URL,
      cache: new InMemoryCache(),
      headers: {
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Credentials': 'true',
        authorization: token ? `Bearer ${token}` : '',
      },
    });
  }, [token]);

  return (
    <ApolloProvider client={client}>
      <BrowserRouter>
        <OnrampRouter />
        <Toaster
          position="bottom-center"
          toastOptions={{
            style: {
              borderRadius: '10px',
              background: '#181818',
              color: '#fff',
            },
          }}
        />
      </BrowserRouter>
    </ApolloProvider>
  );
};

export default App;
