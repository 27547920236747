export const boxStyle = {
  maxWidth: '100vw',
  maxHeight: '100%',
  position: 'fixed',
  overflowY: 'auto',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 540,
  bgcolor: 'background.paper',
  border: '1px solid #fff',
  boxShadow: 24,
  p: 4,
  m: 1,
  borderRadius: 2,
};

export const cancelStyle = {
  height: 40,
  textTransform: 'none',
  color: '#181818',
  padding: '15px',
};

export const submitStyle = {
  background: '#181818',
  height: 40,
  textTransform: 'none',
  color: '#fff',
  '&:hover': {
    backgroundColor: '#353535',
  },
  padding: '15px',
};

export const cancelButtonStyle = {
  height: 40,
  padding: '15px',
  background: '#fff',
  border: '1px solid #000',
  '&:hover': {
    color: '#585858',
  },
  color: '#000',
  textTransform: 'none',
};

export const submitButtonStyle = {
  height: 40,
  padding: '15px',
  background: '#000',
  '&:hover': {
    backgroundColor: '#585858',
  },
  color: '#fff',
  textTransform: 'none',
};
