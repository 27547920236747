import { Box, Typography, Divider } from '@mui/material';
import { useMemo } from 'react';
import { SmallSkeleton } from './components/SmallSkeleton';

const RetailSpend = ({ dashboardData, selectedIndex }: any) => {
  const totalDollars = useMemo(() => {
    if (dashboardData?.totalDollars) {
      return `$${parseFloat(dashboardData?.totalDollars).toFixed(2)}`;
    }
  }, [dashboardData?.totalDollars]);

  const totalDiscounted = useMemo(() => {
    if (dashboardData?.totalDiscounted) {
      return `$${parseFloat(dashboardData?.totalDiscounted).toFixed(2)}`;
    }
  }, [dashboardData?.totalDiscounted]);

  const duration = () => {
    switch (selectedIndex) {
      case 'DAY':
        return '24 HRS';
      case 'WEEK':
        return '7 DAYS';
      case 'MONTH':
        return '30 DAYS';
    }
  };

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column' }}>
      <Typography fontWeight={600} my={2}>
        Retail Spend
      </Typography>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          backgroundColor: '#FFFFFF',
          width: 450,
          height: 130,
          borderRadius: 2,
          boxShadow: '0px 2px 8px rgba(0, 0, 0, 0.1)',
        }}
      >
        <Box sx={{ flex: 1, mx: 3, my: 2 }}>
          <Typography sx={{ mb: 1.5, color: '#666' }}>
            LAST {duration()}
          </Typography>
          {totalDollars ? (
            <Typography sx={{ mb: 1.5, fontSize: 26, fontWeight: 700 }}>
              {totalDollars}
            </Typography>
          ) : (
            <SmallSkeleton />
          )}
        </Box>
        <Divider
          orientation="vertical"
          sx={{ color: '#666', height: '60%', alignSelf: 'center' }}
        />
        <Box sx={{ flex: 1, mx: 3, my: 2 }}>
          <Typography sx={{ mb: 1.5, color: '#666' }}>DISCOUNTED</Typography>
          {totalDiscounted ? (
            <Typography sx={{ mb: 1.5, fontSize: 26, fontWeight: 700 }}>
              {totalDiscounted}
            </Typography>
          ) : (
            <SmallSkeleton />
          )}
        </Box>
      </Box>
    </Box>
  );
};

export default RetailSpend;
