import React from 'react';
import { Box } from '@mui/material';

interface StatusPillProps {
  active: boolean;
  text?: string;
}

export const StatusPill = ({ active, text }: StatusPillProps) => {
  const pillText = () => {
    if (text) {
      return text;
    }

    return active ? 'Active' : 'Inactive';
  };

  return (
    <Box
      width="max-content"
      px="12px"
      py="4px"
      bgcolor={active ? 'green' : '#cc0000'}
      color="white"
      fontSize={12}
      fontWeight={600}
      borderRadius={2}
    >
      {pillText()}
    </Box>
  );
};
