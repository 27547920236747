import React, { useState } from 'react';

import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import MUIDrawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import { useNavigate } from 'react-router-dom';

import logo from '../../logo.png';
import AuthService from '@/merchant/services/auth';

const drawerWidth = 240;

export const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  justifyContent: 'flex-end',
}));

const Drawer = ({ merchantUuid }: { merchantUuid: string }) => {
  const [selected, setSelected] = useState<string>('');
  const navigate = useNavigate();
  const isTechnician = AuthService.isTechnician();

  const adminMenuList = [
    {
      text: 'Dashboard',
      onClick: () => {
        navigate(`/merchants/${merchantUuid}/dashboard`);
        setSelected('Dashboard');
      },
    },
    {
      text: 'Transactions',
      onClick: () => {
        navigate(`/merchants/${merchantUuid}/transactions`);
        setSelected('Transactions');
      },
    },
    {
      text: 'Members',
      onClick: () => {
        navigate(`/merchants/${merchantUuid}/members`);
        setSelected('Members');
      },
    },
  ];

  const techMenuList = [
    {
      text: 'Stations',
      onClick: () => {
        navigate(`/merchants/stations`);
        setSelected('Stations');
      },
    },
  ];

  const getMenuList = () => (isTechnician ? techMenuList : adminMenuList);

  return (
    <MUIDrawer
      sx={{
        width: drawerWidth,
        flexShrink: 0,
        '& .MuiDrawer-paper': {
          width: drawerWidth,
          boxSizing: 'border-box',
        },
      }}
      variant="persistent"
      anchor="left"
      open
    >
      <Box
        component="img"
        sx={{
          height: 63,
          width: 63,
          marginTop: '35px',
          marginLeft: '32px',
          marginBottom: '20px',
        }}
        alt="Onramp logo"
        src={logo}
      />
      <List>
        {getMenuList().map((item) => {
          const { text, onClick } = item;
          return (
            <ListItem
              key={text}
              selected={selected === text}
              onClick={onClick}
              disablePadding
            >
              <ListItemButton>
                <ListItemText primary={text} />
              </ListItemButton>
            </ListItem>
          );
        })}
      </List>
    </MUIDrawer>
  );
};

export default Drawer;
