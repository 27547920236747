import axios, { AxiosResponse } from 'axios';
import { Config } from '../config/env';

const apiUrl = Config.MERCHANT_API_URL;

export const addUserService = async (values: any): Promise<AxiosResponse> => {
  return await axios
    .post(`${apiUrl}/merchants/users`, values)
    .then((response) => response)
    .catch((error) => error);
};

export const getAllUsersService = async (
  merchantUuid: string,
  roles: string[],
): Promise<AxiosResponse> => {
  return await axios
    .get(
      `${apiUrl}/merchants/users?merchant_uuid=${merchantUuid}&roles=${roles}`,
    )
    .then((response) => response)
    .catch((error) => error);
};

export const setPasswordService = async (
  merchantUuid: string | undefined,
  values: any,
): Promise<AxiosResponse> => {
  return await axios
    .post(`${apiUrl}/merchants/users/${merchantUuid}/password`, values)
    .then((response) => response)
    .catch((error) => error);
};

export const listMerchantLocationsService = async (
  merchantUuid: string,
): Promise<AxiosResponse> => {
  return await axios
    .get(`${apiUrl}/staff/merchants/${merchantUuid}/locations`)
    .then((response) => response)
    .catch((error) => error);
};
