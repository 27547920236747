const envSettings = window as any;

function getEnvValue(name: string) {
  const envName = process.env[`REACT_APP_${name}`];
  return envName !== undefined ? envName : envSettings[name];
}

export class Config {
  static REPORT_API_URL = getEnvValue('REPORT_API_URL');

  static CARRIER_API_URL = getEnvValue('CARRIER_API_URL');

  static MERCHANT_API_URL = getEnvValue('MERCHANT_API_URL');

  static AUTH_URL = getEnvValue('AUTH_URL');

  static MIXPANEL_KEY = getEnvValue('MIXPANEL_KEY');

  static DEBUG = getEnvValue('DEBUG');

  static GQL_URL = getEnvValue('GQL_URL');
}
