import React from 'react';
import { Navigate } from 'react-router-dom';
import AuthService from '@/merchant/services/auth';

// If user is logged in execute doLogout if not redirect
// "home" and get redirected to login page
export default function LogoutPage() {
  if (AuthService.isLoggedIn()) {
    return <>{AuthService.doLogout()}</>;
  }
  return <Navigate replace to="/" />;
}
