import { Box, Typography } from '@mui/material';
import Gallons from './Gallons';
import RetailSpend from './RetailSpend';
import Transactions from './Transactions';
import { useLazyQuery } from '@apollo/client';
import { GET_DASHBOARD_INFO } from '@/merchant/graphql/queries/queries';
import { useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { groupBy, map, sumBy } from 'lodash';

const Dashboard = () => {
  const [selectedIndex, setSelectedIndex] = useState('DAY');
  const { merchantUuid } = useParams();

  const [getDasbhoardInfo, { data, loading: dashboardLoading }] = useLazyQuery(
    GET_DASHBOARD_INFO,
    {
      fetchPolicy: 'cache-first',
    },
  );

  useEffect(() => {
    getDasbhoardInfo({
      variables: {
        parentUuid: merchantUuid,
        timeFrame: selectedIndex,
      },
    });
  }, [merchantUuid, selectedIndex]);

  const chartData = useMemo(() => {
    if (selectedIndex === 'DAY') {
      return data?.getDashboardInfo?.dataPoints;
    }

    const groupedByDate = groupBy(
      data?.getDashboardInfo?.dataPoints,
      (item) => item.time.split('T')[0],
    );

    // Takes objects grouped by date and adds the sum of gallons
    // and dollars so only one tick appears on the line chart
    const groupedArray = map(groupedByDate, (group, date) => {
      const dateObjectUTC = new Date(date);
      const dateObjectLocal = new Date(
        dateObjectUTC.getTime() + dateObjectUTC.getTimezoneOffset() * 60000,
      );
      const accurateUTCDateString = dateObjectLocal.toISOString();
      return {
        time: accurateUTCDateString,
        gallon: sumBy(group, 'gallon'),
        dollar: sumBy(group, 'dollar'),
      };
    });

    return groupedArray;
  }, [data?.getDashboardInfo, selectedIndex]);

  const dashboardData = useMemo(() => {
    return data?.getDashboardInfo;
  }, [data]);

  return (
    <>
      <Typography sx={{ fontSize: 20, fontWeight: 600 }}>Dashboard</Typography>
      <Box sx={{ maxWidth: 1000, my: 4, mx: 6 }}>
        <Transactions
          selectedIndex={selectedIndex}
          setSelectedIndex={setSelectedIndex}
          chartData={chartData}
        />
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
          }}
        >
          <Gallons
            dashboardData={dashboardData}
            selectedIndex={selectedIndex}
            loading={dashboardLoading}
          />
          <RetailSpend
            dashboardData={dashboardData}
            selectedIndex={selectedIndex}
          />
        </Box>
      </Box>
    </>
  );
};

export default Dashboard;
