import _ from 'lodash';
import { usePapaParse } from 'react-papaparse';

interface IProps {
  data: any;
  columns: {
    name: string;
    label: string;
  }[];
  fileName: string;
  fromJson: boolean;
}

export const ExportDataAsCSV = ({
  data,
  columns,
  fileName,
  fromJson,
}: IProps): void => {
  const { jsonToCSV } = usePapaParse();

  const exportableData = data.map((line: any) => {
    const newLine: any = {};
    columns.map(
      (column: any) => (newLine[column.label] = _.get(line, column.name)),
    );
    return newLine;
  });
  const csvContent =
    fromJson === true ? jsonToCSV(exportableData) : exportableData;

  const csvData = new Blob([`${csvContent}`], {
    type: 'text/csv;charset=utf-8;',
  });

  const csvURL = window.URL.createObjectURL(csvData);
  const link = document.createElement('a');
  link.href = csvURL as string;
  link.setAttribute('download', `${fileName}.csv`);
  link.click();
  link.remove();
};
