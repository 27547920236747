import { Box, Button, Typography } from '@mui/material';
import TransactionChart from './TransactionChart';
import { useMemo } from 'react';

const DURATION_FORMATS = {
  DAY: 'h:mm a',
  WEEK: 'E',
  MONTH: 'LLL d',
};

interface TransactionProps {
  selectedIndex: string;
  setSelectedIndex: React.Dispatch<React.SetStateAction<string>>;
  chartData: any;
}

const ProgressBar = ({
  columns,
  selectedIndex,
}: {
  columns: { label: string; amount: number; value: string }[];
  selectedIndex: string;
}) => {
  return (
    <Box
      sx={{
        height: 'auto',
        width: '10px',
        ml: 1,
        mr: 4,
        borderRadius: 2,
        backgroundColor: '#D7D7DD',
      }}
    >
      {columns.map((c) => {
        return (
          <Box
            key={c.label}
            sx={{
              flex: 1,
              backgroundColor:
                selectedIndex === c.value ? '#CCEECF' : '#D7D7DD',
              height: `${100 / columns.length}%`,
              width: '10px',
              borderRadius: 10,
            }}
          />
        );
      })}
    </Box>
  );
};

const Transactions = ({
  selectedIndex,
  setSelectedIndex,
  chartData,
}: TransactionProps) => {
  const xAxisFormat = useMemo(() => {
    switch (selectedIndex) {
      case 'DAY':
        return DURATION_FORMATS.DAY;
      case 'WEEK':
        return DURATION_FORMATS.WEEK;
      case 'MONTH':
        return DURATION_FORMATS.MONTH;
      default:
        return DURATION_FORMATS.DAY;
    }
  }, [selectedIndex]);

  const columns = [
    {
      label: '24 HOURS',
      value: 'DAY',
    },
    {
      label: '7 DAYS',
      value: 'WEEK',
    },
    {
      label: '30 DAYS',
      value: 'MONTH',
    },
  ];

  return (
    <>
      <Typography sx={{ fontSize: 18, fontWeight: 600, mb: 1.5 }}>
        Transactions
      </Typography>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          backgroundColor: '#FFF',
          borderRadius: 2,
          boxShadow: '0px 2px 8px rgba(0, 0, 0, 0.1)',
          p: 2,
          mb: 3,
        }}
      >
        {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
        {/* @ts-ignore - TODO: ASAP resolve type mismatch for 'columns' prop in ProgressBar component */}
        <ProgressBar columns={columns} selectedIndex={selectedIndex} />
        <Box
          flex={1}
          display="flex"
          flexDirection="column"
          justifyContent="space-between"
          my={3}
        >
          {columns.map((c) => {
            return (
              <Box key={c.label}>
                <Button
                  onClick={() => setSelectedIndex(c.value)}
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'flex-start',
                    color: 'black',
                  }}
                >
                  <Typography fontSize={16} fontWeight={600}>
                    {c.label}
                  </Typography>
                </Button>
              </Box>
            );
          })}
        </Box>
        <TransactionChart chartData={chartData} xAxisFormat={xAxisFormat} />
      </Box>
    </>
  );
};

export default Transactions;
