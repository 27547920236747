import { useEffect, useState } from 'react';
import { Box, Tooltip, Typography } from '@mui/material';
import MUIDataTable, { MUIDataTableColumnDef } from 'mui-datatables';
import { format } from 'date-fns';
import { StatusPill } from '@/merchant/components/common/StatusPill';
import toast from 'react-hot-toast';

import { useLazyQuery } from '@apollo/client';
import {
  GET_MERCHANT_LOCATIONS,
  GET_USER,
} from '@/merchant/graphql/queries/queries';

const convertSiteStatus = (status: string) => {
  switch (status) {
    case 'DISCONNECTED':
      return <StatusPill active={false} text="Disconnected" />;
    case 'CONNECTED':
      return <StatusPill active={true} text="Connected" />;
    default:
      return <StatusPill active={false} text="Status unavailable" />;
  }
};

const Stations = ({ entityUuid }: { entityUuid: string }) => {
  const [parentUuids, setParentUuids] = useState<string[] | null>(null);

  const [getUser, { data: userData }] = useLazyQuery(GET_USER, {
    variables: {
      entityUuid: entityUuid,
      role: 'MERCHANT_TECHNICIAN',
    },
    onCompleted: () => {
      if (userData) {
        setParentUuids(userData.getUserProfile.parentUuids);
      }
    },
  });

  const [getLocations, { data, loading, error }] = useLazyQuery(
    GET_MERCHANT_LOCATIONS,
    {
      fetchPolicy: 'network-only',
      variables: {
        merchantIds: parentUuids,
      },
    },
  );

  useEffect(() => {
    const getData = async () => {
      if (!entityUuid) return;
      await getUser();
    };

    getData();
  }, [entityUuid, parentUuids]);

  useEffect(() => {
    if (!parentUuids) return;
    getLocations();
  }, [parentUuids]);

  useEffect(() => {
    if (error) {
      toast.error(
        `Unable to get locations. Please try again later. Error: ${error.message}`,
      );
    }
  }, [error]);

  const columns: MUIDataTableColumnDef[] = [
    {
      label: 'ID',
      name: 'id',
      options: {
        display: false,
        customBodyRender: (id) => {
          return (
            <Tooltip title={id}>
              <Typography
                onClick={() => {
                  navigator.clipboard.writeText(id);
                  toast.success('id copied');
                }}
              >
                {id.substring(0, 8) + '...'}
              </Typography>
            </Tooltip>
          );
        },
      },
    },
    {
      label: 'Ext ID',
      name: 'externalId',
    },
    {
      label: 'Name',
      name: 'name',
    },
    {
      label: 'Merchant Name',
      name: 'merchantName',
    },
    {
      label: 'Address',
      name: 'address',
      options: {
        customBodyRender: (address) => {
          if (address) {
            return (
              <span>
                {`${address.street}, ${address.city}, ${address.state}, ${address.zipCode}`}
              </span>
            );
          }
        },
      },
    },
    {
      label: 'Last Connection',
      name: 'lastHeartBeat',
      options: {
        customBodyRender: (dateTime) => {
          // BE defaults null values to 0001-01-01T00:00:00Z
          if (dateTime === '0001-01-01T00:00:00Z') {
            return 'N/A';
          }

          return format(new Date(dateTime), 'eee, MMM d, yyyy - h:mm a');
        },
      },
    },
    {
      label: 'Status',
      name: 'siteStatus',
      options: {
        customBodyRender: (status) => {
          return convertSiteStatus(status);
        },
      },
    },
  ];

  return (
    <>
      <MUIDataTable
        columns={columns}
        data={data?.getLocations}
        title="Locations"
        options={{
          responsive: 'simple',
          enableNestedDataAccess: '.',
          selectableRowsHideCheckboxes: true,
          download: true,
          filter: true,
          print: false,
          rowsPerPage: 10,
          rowsPerPageOptions: [10, 20, 50],
          textLabels: {
            body: {
              noMatch: loading ? null : (
                <Box sx={{ py: 4 }}>No locations found</Box>
              ),
            },
          },
        }}
      />
    </>
  );
};

export default Stations;
