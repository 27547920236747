import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { format, subDays } from 'date-fns';

import MUIDataTable, { MUIDataTableColumnDef } from 'mui-datatables';
import { Box, Button, TextField, CircularProgress } from '@mui/material';
import { LocalizationProvider, DatePicker } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import {
  listLocationTransactionsService,
  listMerchantTransactionsService,
} from '@/merchant/services/transactions';
import currencyFormatter from '@/merchant/components/helpers/currencyFormatter';
import ErrorAlert from '@/merchant/components/common/alerts/error';
import SuccessAlert from '@/merchant/components/common/alerts/success';

import ExportTransactionsModal from './ExportTransactionsModal';
import AuthService from '@/merchant/services/auth';

const columns: MUIDataTableColumnDef[] = [
  {
    label: 'Date',
    name: 'date',
    options: {
      customBodyRender: (value) => format(Date.parse(value), 'MM/dd/yyyy'),
    },
  },
  {
    label: 'Time (EST)',
    name: 'time',
    options: {
      customBodyRender: (value) => value.replace(' EST', ''),
    },
  },
  {
    label: 'Station',
    name: 'truckStopName',
  },
  {
    label: 'City',
    name: 'city',
  },
  {
    label: 'State',
    name: 'region',
  },
  {
    label: '#',
    name: 'transactionNumber',
    options: {
      customBodyRender: (value) => value.substring(11),
    },
  },
  {
    label: 'Product',
    name: 'productName',
  },
  {
    label: 'Qty',
    name: 'quantity',
    options: {
      customBodyRender: (value) => Number(value.value).toFixed(3),
    },
  },
  {
    label: 'Unit Price',
    name: 'unitDiscountedCost',
    options: {
      customBodyRender: (row) => {
        return currencyFormatter.format(Number(row.value));
      },
    },
  },
  {
    label: 'Discounted Total',
    name: 'productDiscountedTotal',
    options: {
      customBodyRender: (row) => {
        return currencyFormatter.format(Number(row.value));
      },
    },
  },
];

interface StyleButtonProps {
  children: any;
  onClick: any;
  inverted?: boolean;
  style?: any;
  disabled?: boolean;
}

// @TODO: find a standard place for this
const StyledButton = ({
  children,
  inverted = false,
  onClick,
  style,
  disabled,
}: StyleButtonProps) => (
  <Button
    onClick={onClick}
    sx={{
      height: 40,
      textTransform: 'none',
      // @TODO: find hex value
      background: inverted ? '#fff' : '#181818',
      color: inverted ? '#000' : '#fff',
      '&:hover': {
        backgroundColor: inverted ? 'light-gray' : '#353535',
      },
      ':disabled': {
        background: '#ccc',
      },
      padding: '15px',
      ...style,
    }}
    disabled={disabled}
  >
    {children}
  </Button>
);

export default function TransactionsView() {
  const { merchantUuid } = useParams();
  const [data, setData] = useState<any[]>([]);
  const [filteredData, setFilteredData] = useState<any[]>([]);
  const [open, setOpen] = useState<boolean>(false);
  const [filterStartDate, setFilterStartDate] = useState<Date | null>(null);
  const [filterEndDate, setFilterEndDate] = useState<Date | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [disableButton, setDisableButton] = useState<boolean>(true);
  const [filterApplied, setFilterApplied] = useState<boolean>(false);
  const [message, setMessage] = useState('');
  const [openSuccess, setOpenSuccess] = useState(false);
  const [openError, setOpenError] = useState(false);

  const isStoreAdmin = AuthService.isStoreAdmin();

  const handleApplyFilter = async () => {
    let filteredTransactions: any[] = [];

    if (filterStartDate && filterEndDate) {
      filteredTransactions = await data.filter(
        (e: any) =>
          new Date(e.date) >= filterStartDate &&
          new Date(e.date) <= filterEndDate,
      );
    }

    await setFilterApplied(true);
    await setFilteredData(filteredTransactions);
  };

  const handleRemoveFilter = () => {
    setFilterStartDate(null);
    setFilterEndDate(null);
    setFilterApplied(false);
  };

  const startDate = subDays(new Date(), 30);
  const endDate = new Date();
  const sDate = startDate !== null ? startDate.toISOString() : '';
  const eDate = endDate !== null ? endDate.toISOString() : '';
  const cuuid = merchantUuid !== undefined ? merchantUuid.toString() : '';

  useEffect(() => {
    setLoading(true);

    if (isStoreAdmin) {
      listLocationTransactionsService(cuuid, sDate, eDate).then((response) => {
        if (response.status === 200) {
          setData(response.data.transactionRecords);
        } else {
          setOpenError(true);
          setMessage(
            `There was an issue with 
            code:${response.data.code} loading transactions, 
            contact support.`,
          );
        }
      });
    } else {
      listMerchantTransactionsService(cuuid, sDate, eDate)
        .then((response) => {
          if (response.status === 200) {
            setData(response.data.transactionRecords);
          } else {
            setOpenError(true);
            setMessage(
              `There was an issue with 
            code:${response.data.code} loading transactions, 
            contact support.`,
            );
          }
        })
        .catch(() => {
          setOpenError(true);
          setMessage(
            'There was an issue loading transactions, contact support.',
          );
        });
      setLoading(false);
    }
  }, [isStoreAdmin]);

  useEffect(() => {
    if (filterStartDate && filterEndDate) {
      setDisableButton(false);
    } else {
      setDisableButton(true);
    }
  }, [filterStartDate, filterEndDate]);

  return (
    <div style={{ height: 400, width: '100%' }}>
      <Box
        marginBottom={4}
        display="flex"
        justifyContent="space-between"
        alignItems="center"
      >
        <Box display="flex" alignItems="center">
          <Box display="flex">
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <Box marginRight={1}>
                <DatePicker
                  label="Start date"
                  disableFuture
                  maxDate={filterEndDate}
                  value={filterStartDate}
                  onChange={(newValue) => {
                    setFilterStartDate(newValue);
                  }}
                  renderInput={(params) => (
                    <TextField size="small" {...params} />
                  )}
                />
              </Box>
              <Box marginRight={1}>
                <DatePicker
                  label="End date"
                  disableFuture
                  minDate={filterStartDate}
                  value={filterEndDate}
                  onChange={(newValue) => {
                    setFilterEndDate(newValue);
                  }}
                  renderInput={(params) => (
                    <TextField size="small" {...params} />
                  )}
                />
              </Box>
            </LocalizationProvider>
          </Box>
          <Box
            display="flex"
            justifyContent="flex-start"
            alignItems="flex-start"
          >
            <StyledButton
              disabled={disableButton}
              onClick={filterApplied ? handleRemoveFilter : handleApplyFilter}
            >
              {filterApplied ? 'Remove Filter' : 'Apply Filter'}
            </StyledButton>
          </Box>
        </Box>
        <StyledButton onClick={() => setOpen(true)}>
          Export Transactions
        </StyledButton>
      </Box>
      <MUIDataTable
        columns={columns}
        data={filterApplied ? filteredData : data}
        title="Transactions"
        options={{
          enableNestedDataAccess: '.',
          selectableRowsHideCheckboxes: true,
          filter: false,
          download: false,
          print: false,
          rowsPerPage: 10,
          rowsPerPageOptions: [10, 20, 50],
          textLabels: {
            body: {
              noMatch: loading ? (
                <CircularProgress
                  sx={{
                    color: '#181818',
                  }}
                />
              ) : (
                'No transactions found'
              ),
            },
          },
        }}
      />
      <SuccessAlert
        message={message}
        open={openSuccess}
        handleClose={() => setOpenSuccess(false)}
      />
      <ErrorAlert
        message={message}
        open={openError}
        handleClose={() => setOpenError(false)}
      />
      {open && <ExportTransactionsModal handleClose={() => setOpen(false)} />}
    </div>
  );
}
