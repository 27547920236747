import { gql } from '@apollo/client';

export const GET_DASHBOARD_INFO = gql`
  query GetDashboardInfo($parentUuid: String!, $timeFrame: DashBoardTime!) {
    getDashboardInfo(parentUuid: $parentUuid, timeFrame: $timeFrame) {
      activePump
      totalGallons
      totalDollars
      totalDiscounted
      dataPoints {
        time
        dollar
        gallon
      }
    }
  }
`;

export const GET_MERCHANT_LOCATIONS = gql`
  query GetLocations($merchantIds: [String]) {
    getLocations(merchantIds: $merchantIds) {
      id
      name
      address {
        street
        city
        state
        zipCode
      }
      phone
      lat
      long
      mapMarkerUrl
      locationBannerUrl
      numberOfDispensers
      tid
      merchantName
      acceptedPaymentTypes
      insidePaymentTypes
      outsidePaymentTypes
      insideOnly
      pumpNumbers
      price
      retailPrice
      showDiscount
      siteStatus
      externalId
      storeNumber
      active
      lastHeartBeat
    }
  }
`;

export const GET_USER = gql`
  query GetUserProfile($entityUuid: String!, $role: String!) {
    getUserProfile(entityUuid: $entityUuid, role: $role) {
      parentUuids
    }
  }
`;
