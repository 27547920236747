import React from 'react';
import { Navigate, Outlet, useParams } from 'react-router-dom';
import AuthService from '@/merchant/services/auth';
import PageLayout from '../PageLayout';

const UserHasPermissions = () => {
  const { merchantUuid } = useParams();
  const merchantUuidUpdated =
    merchantUuid && merchantUuid === 'undefined' ? undefined : merchantUuid;
  const entityUuid = AuthService.getEntityUuid();
  const uuid = merchantUuidUpdated || entityUuid;
  const userHasAccess =
    AuthService.hasRole(['merchant_admin']) ||
    (AuthService.hasRole(['store_admin']) && uuid);

  const isTechnician = AuthService.hasRole(['merchant_technician']);

  if (!AuthService.isLoggedIn()) {
    return <>{AuthService.doLogin()}</>;
  }

  if (isTechnician) {
    return (
      <PageLayout merchantUuid={uuid}>
        <Outlet />
      </PageLayout>
    );
  }

  if (uuid === 'undefined' || uuid === undefined) {
    // Covers the edge case for staff users that might not have
    // a valid merchant uuid and/or are coming straight to the merchant app.
    return <Navigate to="/not-found" />;
  }

  if (!merchantUuidUpdated && entityUuid) {
    return <Navigate to={`merchants/${uuid}/transactions`} />; // todo replace with dashboard
  }

  if (userHasAccess || AuthService.isStaffUser()) {
    return (
      <PageLayout merchantUuid={uuid}>
        <Outlet />
      </PageLayout>
    );
  }

  return <Navigate to="/access-denied" />;
};

export default UserHasPermissions;
