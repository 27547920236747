import React from 'react';
import { Route, Routes } from 'react-router-dom';
import Members from '@/merchant/components/views/members/MembersView';
import NotAuthorized from './NotAuthorized';
import NotFound from './NotFound';
import LoginPage from './LoginPage';
import UserHasPemissions from './UserHasPermissions';
import TransactionsView from '../views/transactions/TransactionsView';
import PublicPageLayout from '../PublicPageLayout';
import LogoutPage from './LogoutPage';
import Dashboard from '@/merchant/components/views/dashboard/Dashboard';
import Stations from '@/merchant/components/views/stations/StationsView';
import AuthService from '@/merchant/services/auth';

function publicRoutes() {
  return (
    <Route element={<PublicPageLayout />}>
      <Route path="/login" element={<LoginPage />} />
      <Route path="/logout" element={<LogoutPage />} />
    </Route>
  );
}

function privateRoutes() {
  const entityUuid = AuthService.getEntityUuid();
  const isTechnician = AuthService.hasRole(['merchant_technician']);

  if (isTechnician) {
    return (
      <>
        <Route path="/" element={<UserHasPemissions />}>
          <Route
            path="merchants/stations"
            element={<Stations entityUuid={entityUuid} />}
          />
        </Route>
        <Route path="/access-denied" element={<NotAuthorized />} />
      </>
    );
  }

  return (
    <>
      <Route path="/" element={<UserHasPemissions />}>
        <Route // landing page for merchants
          path="merchants/:merchantUuid/dashboard"
          element={<Dashboard />}
        />
        <Route
          path="merchants/stations"
          element={<Stations entityUuid={entityUuid} />}
        />
        <Route
          path="merchants/:merchantUuid/transactions"
          element={<TransactionsView />}
        />
        <Route path="merchants/:merchantUuid/members" element={<Members />} />
      </Route>
      <Route path="/access-denied" element={<NotAuthorized />} />
    </>
  );
}
const AuthenticatedOnrampRouter = () => (
  <Routes>
    {privateRoutes()}
    {publicRoutes()}
    <Route path="*" element={<NotFound />} />
  </Routes>
);

export default AuthenticatedOnrampRouter;
