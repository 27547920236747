import { useState, useEffect } from 'react';
import axios from 'axios';
import { useParams } from 'react-router-dom';

import { Config } from '../config/env';

interface IMerchant {
  uuid: string;
  externalId: string;
  name: string;
}

function userMerchant() {
  const [merchant, setMerchant] = useState<IMerchant>({
    uuid: '',
    externalId: '',
    name: '',
  });

  const { merchantUuid } = useParams();

  useEffect(() => {
    const getData = async () => {
      if (merchantUuid === undefined) throw Error('missing merchantUuid');

      await axios
        .get(`${Config.MERCHANT_API_URL}/merchants/${merchantUuid}`)
        .then((response) => setMerchant(response.data.merchant))
        .catch((error) => error);
    };
    getData();
  }, [merchantUuid !== undefined]);

  return merchant;
}

export default userMerchant;
