import { Box, Divider, Typography } from '@mui/material';
import { useMemo } from 'react';
import { SmallSkeleton } from './components/SmallSkeleton';

const Gallons = ({ dashboardData, selectedIndex, loading }: any) => {
  const totalGallons = useMemo(() => {
    if (dashboardData?.totalGallons) {
      return dashboardData?.totalGallons;
    }
  }, [dashboardData?.totalGallons]);

  const mostActivePump = useMemo(() => {
    if (dashboardData?.activePump !== undefined) {
      return dashboardData?.activePump;
    }
  }, [dashboardData?.activePump]);

  const duration = () => {
    switch (selectedIndex) {
      case 'DAY':
        return '24 HRS';
      case 'WEEK':
        return '7 DAYS';
      case 'MONTH':
        return '30 DAYS';
    }
  };

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column' }}>
      <Typography fontWeight={600} my={2}>
        Gallons
      </Typography>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          backgroundColor: '#FFFFFF',
          width: 450,
          height: 130,
          borderRadius: 2,
          boxShadow: '0px 2px 8px rgba(0, 0, 0, 0.1)',
        }}
      >
        <Box sx={{ flex: 1, mx: 3, my: 2 }}>
          <Typography sx={{ mb: 1.5, color: '#666' }}>
            LAST {duration()}
          </Typography>
          {totalGallons ? (
            <Typography sx={{ mb: 1.5, fontSize: 26, fontWeight: 700 }}>
              {totalGallons}
            </Typography>
          ) : (
            <SmallSkeleton />
          )}
        </Box>
        <Divider
          orientation="vertical"
          sx={{ color: '#666', height: '60%', alignSelf: 'center' }}
        />
        <Box sx={{ flex: 1, mx: 3, my: 2 }}>
          <Typography sx={{ mb: 1.5, color: '#666' }}>MOST ACTIVE</Typography>
          {mostActivePump ? (
            <Typography sx={{ mb: 1.5, fontSize: 26, fontWeight: 700 }}>
              {mostActivePump}
            </Typography>
          ) : (
            <>
              {loading ? (
                <SmallSkeleton />
              ) : (
                <Typography sx={{ mb: 1.5, fontSize: 26, fontWeight: 700 }}>
                  N/A
                </Typography>
              )}
            </>
          )}
        </Box>
      </Box>
    </Box>
  );
};

export default Gallons;
