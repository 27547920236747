import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import AuthService from './services/auth';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement,
);

const renderApp = () =>
  root.render(
    <React.StrictMode>
      <App />
    </React.StrictMode>,
  );

AuthService.initKeycloak(renderApp);
