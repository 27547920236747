import React from 'react';
import Grid from '@mui/material/Grid';
import { Typography } from '@mui/material';

const NotFound = () => (
  <Grid
    container
    spacing={0}
    direction="column"
    alignItems="center"
    justifyContent="center"
    style={{ minHeight: '100vh' }}
  >
    <Typography variant="h3">404 - page not found</Typography>
  </Grid>
);

export default NotFound;
