import React from 'react';
import { styled, createTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import { ThemeProvider } from '@mui/system';
import Drawer, { DrawerHeader } from './navigation/Drawer';

import AppBar from './navigation/AppBar';

interface Props {
  children?: React.ReactNode;
  merchantUuid: string;
}

const Main = styled('main')(({ theme }) => ({
  flexGrow: 1,
  padding: theme.spacing(3),
  background: '#f8f8fa',
  transition: theme.transitions.create('margin', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  marginLeft: 0,
  '& .MuiTableCell-head': {
    paddingLeft: 0,
    marginLeft: 10,
  },
}));

const thm = createTheme({
  palette: {
    background: {
      default: '#f8f8fa',
    },
  },
  components: {
    MUIDataTableViewCol: {
      styleOverrides: {
        title: {
          padding: '14px 10px 0px 10px',
        },
      },
    },
    MuiFormControlLabel: {
      styleOverrides: {
        // @ts-expect-error: TS2322
        labelPlacementEnd: {
          paddingLeft: '20px',
          marginBottom: '5px',
        },
      },
    },
  },
});

const PageLayout = ({ children, merchantUuid }: Props) => {
  return (
    <ThemeProvider theme={thm}>
      <CssBaseline />
      <Box sx={{ display: 'flex' }}>
        <AppBar />
        <Drawer merchantUuid={merchantUuid} />
        <Main>
          <DrawerHeader />
          {children}
        </Main>
      </Box>
    </ThemeProvider>
  );
};

export default PageLayout;
