import { Skeleton } from '@mui/material';

export const SmallSkeleton = () => (
  <Skeleton
    variant="rounded"
    animation="wave"
    width={100}
    height={40}
    sx={{ marginBottom: 1.5 }}
  />
);
