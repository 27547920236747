import axios, { AxiosResponse } from 'axios';
import { Config } from '../config/env';

const apiUrl = Config.REPORT_API_URL;

export const listMerchantTransactionsService = async (
  merchantUuid: string,
  startDate: string,
  endDate: string,
): Promise<AxiosResponse> => {
  return await axios
    .get(
      `${apiUrl}/reports/merchants/${merchantUuid}/transactions?startDateTime=${startDate}&endDateTime=${endDate}`,
    )
    .then((response) => response)
    .catch((error) => error);
};

export const listLocationTransactionsService = async (
  merchantUuid: string,
  startDate: string,
  endDate: string,
): Promise<AxiosResponse> => {
  return await axios
    .get(
      `${apiUrl}/reports/merchants/${merchantUuid}/locations/transactions?startDateTime=${startDate}&endDateTime=${endDate}`,
    )
    .then((response) => response)
    .catch((error) => error);
};
