import axios from 'axios';
import AuthService from '@/merchant/services/auth';

axios.defaults.withCredentials = window.location.host.includes('fleetapp');

axios.interceptors.request.use(
  async (config) => {
    config.headers = {
      Authorization: `Bearer ${AuthService.getToken()}`,
      'Content-Type': 'application/json',
    };
    return config;
  },
  (error) => {
    console.log(error);
    Promise.reject(error);
  },
);
