import mixpanel from 'mixpanel-browser';
import { Config } from './env';

export const initMixpanel = async () => {
  if (Config.MIXPANEL_KEY) {
    await mixpanel.init(Config.MIXPANEL_KEY, { debug: Config.DEBUG });
  } else {
    console.log('Mixpanel not initialized');
  }
};

const actions = {
  identify: (id: any) => {
    return mixpanel.identify(id);
  },
  alias: (id: any) => {
    return mixpanel.alias(id);
  },
  track: (name: any, props?: any) => {
    try {
      return mixpanel.track(name, props);
    } catch (e) {
      console.log({ e });
    }
  },
  people: {
    set: (props: any) => {
      return mixpanel.people.set(props);
    },
  },
};

export const Mixpanel = actions;
